@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
	padding: 0%;
	margin: 0%;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
}

// VARIABLES START
$color1: #08243b;
$color2: #606060;
// VARIABLES END

// GLOBAL START
html {
	width: 100%;
	font-size: 100%;
	overflow-x: hidden;
	scroll-behavior: auto !important;
}
@media screen and (max-width: 992px) {
	html {
		font-size: 100%;
	}
}
@media screen and (max-width: 668px) {
	html {
		font-size: 92%;
	}
}
@media screen and (max-width: 575px) {
	html {
		font-size: 88%;
	}
}
@media screen and (max-width: 475px) {
	html {
		font-size: 84%;
	}
}
@media screen and (max-width: 380px) {
	html {
		font-size: 80%;
	}
}
@media screen and (max-width: 280px) {
	html {
		font-size: 76%;
	}
}

.pointer {
	cursor: pointer;
}

.f8 {
	font-size: 0.5rem;
}

.f10 {
	font-size: 0.625rem;
}

.f12 {
	font-size: 0.75rem;
}

.f14 {
	font-size: 0.875rem;
}

.f15 {
	font-size: 0.938rem;
}

.f16 {
	font-size: 1rem;
}

.f18 {
	font-size: 1.125rem;
}

.f20 {
	font-size: 1.25rem;
}

.f22 {
	font-size: 1.375rem;
}

.f24 {
	font-size: 1.5rem;
}

.f34 {
	font-size: 2.125rem;
}

.f30 {
	font-size: 1.875rem;
}

.f35 {
	font-size: 2.125rem;
	line-height: 57px;
}

.f50 {
	font-size: 3.125rem;
	line-height: 65px;
}

.f64 {
	font-size: 4rem;
	line-height: 71px;
}

.fw400 {
	font-weight: 400;
}

.fw500 {
	font-weight: 500;
}

.fw600 {
	font-weight: 600;
}

.fw700 {
	font-weight: 700;
}

.fw800 {
	font-weight: 800;
}

.fw900 {
	font-weight: 900;
}

// COLORS
.color1 {
	color: $color1;
}

.color2 {
	color: $color2;
}

.color3 {
	color: #2dc095;
}

.themeBtn {
	background: linear-gradient(125deg, #24a6a8 17%, rgb(51, 209, 137) 100%);
	box-shadow: 1px 1px 4px rgb(51, 209, 137);
}

.active_option {
	background: linear-gradient(
		90deg,
		#ffd33d,
		#2dc095 17%,
		#b34bff 34%,
		#00c3cc 51%,
		#ffd33d 68%,
		#33d18a 85%,
		#b34bff
	) !important;
	background-size: 300% 100% !important;
	box-shadow: 1px 1px 4px rgb(47, 223, 144);
	animation: progress-animation2 2s linear infinite;
}

@keyframes progress-animation2 {
	0% {
		background-position: 100%;
	}
	100% {
		background-position: 0;
	}
}

body {
	background: linear-gradient(
		40deg,
		#33d18a34,
		#24a9a934 17%,
		#28b2a02c 34%,
		#33d18a28
	);
	background-size: 300% 100%;
	animation: progress-animation 2s linear infinite;
}

@keyframes progress-animation {
	0% {
		background-position: 100%;
	}
	100% {
		background-position: 0;
	}
}

// FORM CONTAINER START
.form_container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 40px);

	.logo_container {
		position: fixed;
		top: 1rem;
		left: 1rem;
		height: 70px;
		width: auto;
	}

	.form_inner_container {
		max-width: 550px;
		padding: 0rem 2rem;
		width: 100%;
		margin-left: auto;
		margin-right: auto;

		&::-webkit-scrollbar {
			width: 7px;
		}
		&::-webkit-scrollbar-track {
			background: #f1f1f1;
		}
		&::-webkit-scrollbar-thumb {
			background: linear-gradient(125deg, #24a6a8 17%, rgb(51, 209, 137) 100%);
			border-radius: 10px;
		}

		.stepper_header {
			.progress {
				width: 100%;
				max-width: 100%;
				height: 8px;
				background: #e1e4e8;
				border-radius: 50px;
				overflow: hidden;
				transition: 0.6s;
			}
			.progress-bar {
				display: block;
				height: 100%;
				background: linear-gradient(
					90deg,
					#ffd33d,
					#2dc095 17%,
					#b34bff 34%,
					#00c3cc 51%,
					#ffd33d 68%,
					#33d18a 85%,
					#b34bff
				);
				background-size: 300% 100%;
				animation: progress-animation 2s linear infinite;
			}
			@keyframes progress-animation {
				0% {
					background-position: 100%;
				}
				100% {
					background-position: 0;
				}
			}

			button {
				background: linear-gradient(
					125deg,
					#24a6a8 17%,
					rgb(51, 209, 137) 100%
				);
				color: #fff;
				border: 0;
				border-radius: 50px;
				height: 35px;
				box-shadow: 1px 1px 4px rgb(51, 209, 137);

				&:focus {
					outline: none;
				}
			}
		}

		.form_body {
			.step_one {
				padding: 2rem 0rem;

				.bottom_line {
					position: relative;

					&::after {
						content: "";
						position: absolute;
						width: 100%;
						height: 3px;
						left: 0%;
						bottom: 12%;
						border-radius: 50px;
						background: linear-gradient(
							90deg,
							#ffd33d,
							#2dc095 17%,
							#b34bff 34%,
							#00c3cc 51%,
							#ffd33d 68%,
							#33d18a 85%,
							#b34bff
						);
						background-size: 300% 100%;
						animation: progress-animation 2s linear infinite;
					}

					@keyframes progress-animation {
						0% {
							background-position: 100%;
						}
						100% {
							background-position: 0;
						}
					}
				}

				.animation {
					margin: -11rem 0rem -6rem 0rem;
					position: relative;
				}

				button {
					background: linear-gradient(
						125deg,
						#24a6a8 17%,
						rgb(51, 209, 137) 100%
					);
					color: #fff;
					border: 0;
					border-radius: 5px;
					height: 38px;
					box-shadow: 1px 1px 4px rgb(51, 209, 137);
					width: 100%;
					letter-spacing: 1px;
					font-weight: 500;
					cursor: pointer;
					position: absolute;
					bottom: 10%;
				}
			}
		}
	}

	.radio_option {
		background: linear-gradient(
			125deg,
			#24a6a89a 17%,
			rgba(51, 209, 138, 0.603) 100%
		);
		margin: 0.6rem 0rem;
		padding: 0.3rem 0rem;
		color: #fff;
		border-radius: 6px;
	}
}
// FORM CONTAINER END

// REULTS CONTAINER START
.results_container {
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;

	.coach_card {
		min-height: 1px;
		height: 100%;
		transition: 0.8s;

		img {
			max-width: 200px;
			max-height: 200px;
			border-radius: 50%;
			margin-left: auto;
			margin-right: auto;
		}

		&:hover {
			box-shadow: 1px 1px 5px rgb(51, 209, 137);
			transform: translateY(-10px);
		}
	}
}
// REULTS CONTAINER END

// DETAIL START
.pop-up-container {
	position: fixed;
	left: 0%;
	right: 0%;
	bottom: 0%;
	top: 0%;
	width: 100%;
	height: 100vh;
	background: linear-gradient(
		125deg,
		#24a6a89a 17%,
		rgba(51, 209, 138, 0.603) 100%
	);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	margin-top: 0px !important;

	.inner-pop-up {
		max-height: calc(100vh - 200px);
		overflow-y: auto;
		overflow-x: hidden;
		background-color: #fff;
		min-width: 900px;
		max-width: 500px;
		border-radius: 20px;

		&::-webkit-scrollbar {
			width: 7px;
		}
		&::-webkit-scrollbar-track {
			background: #f1f1f1;
		}
		&::-webkit-scrollbar-thumb {
			background: rgba(51, 209, 138, 0.603);
			border-radius: 10px;
		}

		.cross {
			position: absolute;
			right: 0%;
			top: 0%;
		}

		img {
			max-width: 150px;
			max-height: 150px;
			object-fit: cover;
			border-radius: 50%;
		}
	}
}
// DETAIL END

// PAYMENT START
.payment_Container {
	margin: 3rem 0rem;
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;

	img {
		max-width: 150px;
		max-height: 150px;
		object-fit: cover;
		border-radius: 10px;
	}

	.btn_container {
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;

		button {
			width: 100%;
		}
	}
}
// PAYMENT END

// COUNTER START
.counter_container {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 3rem;
	-webkit-animation: blink-1 1s ease-in-out 0.2s infinite both;
	animation: blink-1 1s ease-in-out 0.2s infinite both;

	.countUp::after {
		content: "%";
	}
}

@-webkit-keyframes blink-1 {
	0%,
	50%,
	100% {
		opacity: 1;
	}
	25%,
	75% {
		opacity: 0;
	}
}
@keyframes blink-1 {
	0%,
	50%,
	100% {
		opacity: 1;
	}
	25%,
	75% {
		opacity: 0;
	}
}
// COUNTER END

// POPUPS START
.pop-up-container.form-popup {
	position: fixed;
	left: 0%;
	right: 0%;
	bottom: 0%;
	top: 0%;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;

	.inner-pop-up {
		background-color: #fff;
		min-width: 500px;
		max-width: 500px;
	}

	.pop-up-body {
		padding: 2rem;

		input {
			height: 44px;
			border-radius: 6px;
			padding: 0rem 1rem;
			border: 1px solid #000;

			&:focus {
				outline: none;
			}
		}
		button {
			background: linear-gradient(
				125deg,
				#24a6a89a 17%,
				rgba(51, 209, 138, 0.603) 100%
			);
			margin: 0.6rem 0rem;
			padding: 0.3rem 0rem;
			color: #fff;
			border-radius: 6px;
			border: none;
			height: 40px;
		}
	}

	.error_field {
		border: 2px solid #dc3545 !important;
	}
}
// POPUPS END

// MEDIA START
@media screen and (max-width: 1000px) {
	.pop-up-container {
		.inner-pop-up {
			min-width: calc(100vw - 100px);
		}
	}
}

@media screen and (max-height: 800px) {
	.pop-up-container {
		.inner-pop-up {
			max-height: calc(100vh - 100px);
			overflow-y: auto;
		}
	}
}

@media screen and (max-width: 575px) {
	.pop-up-container {
		padding: 0rem 1rem;

		.inner-pop-up {
			max-height: 500px;
			overflow: auto;
		}
	}
	.pop-up-container.form-popup .inner-pop-up {
		min-width: 1px;
	}
}

@media screen and (max-height: 700px) {
	.form_container {
		.form_inner_container {
			max-height: 500px;
			overflow-y: auto;
			overflow-x: hidden;
		}
	}
}

@media screen and (max-height: 600px) {
	.form_container {
		.form_inner_container {
			max-height: 400px;
			overflow-y: auto;
			overflow-x: hidden;
		}
	}
}
// MEDIA END
