@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  padding: 0%;
  margin: 0%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  width: 100%;
  font-size: 100%;
  overflow-x: hidden;
  scroll-behavior: auto !important;
}

@media screen and (max-width: 992px) {
  html {
    font-size: 100%;
  }
}

@media screen and (max-width: 668px) {
  html {
    font-size: 92%;
  }
}

@media screen and (max-width: 575px) {
  html {
    font-size: 88%;
  }
}

@media screen and (max-width: 475px) {
  html {
    font-size: 84%;
  }
}

@media screen and (max-width: 380px) {
  html {
    font-size: 80%;
  }
}

@media screen and (max-width: 280px) {
  html {
    font-size: 76%;
  }
}

.pointer {
  cursor: pointer;
}

.f8 {
  font-size: 0.5rem;
}

.f10 {
  font-size: 0.625rem;
}

.f12 {
  font-size: 0.75rem;
}

.f14 {
  font-size: 0.875rem;
}

.f15 {
  font-size: 0.938rem;
}

.f16 {
  font-size: 1rem;
}

.f18 {
  font-size: 1.125rem;
}

.f20 {
  font-size: 1.25rem;
}

.f22 {
  font-size: 1.375rem;
}

.f24 {
  font-size: 1.5rem;
}

.f34 {
  font-size: 2.125rem;
}

.f30 {
  font-size: 1.875rem;
}

.f35 {
  font-size: 2.125rem;
  line-height: 57px;
}

.f50 {
  font-size: 3.125rem;
  line-height: 65px;
}

.f64 {
  font-size: 4rem;
  line-height: 71px;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fw800 {
  font-weight: 800;
}

.fw900 {
  font-weight: 900;
}

.color1 {
  color: #08243b;
}

.color2 {
  color: #606060;
}

.color3 {
  color: #2dc095;
}

.themeBtn {
  background: linear-gradient(125deg, #24a6a8 17%, #33d189 100%);
  -webkit-box-shadow: 1px 1px 4px #33d189;
          box-shadow: 1px 1px 4px #33d189;
}

.active_option {
  background: -webkit-gradient(linear, left top, right top, from(#ffd33d), color-stop(17%, #2dc095), color-stop(34%, #b34bff), color-stop(51%, #00c3cc), color-stop(68%, #ffd33d), color-stop(85%, #33d18a), to(#b34bff)) !important;
  background: linear-gradient(90deg, #ffd33d, #2dc095 17%, #b34bff 34%, #00c3cc 51%, #ffd33d 68%, #33d18a 85%, #b34bff) !important;
  background-size: 300% 100% !important;
  -webkit-box-shadow: 1px 1px 4px #2fdf90;
          box-shadow: 1px 1px 4px #2fdf90;
  -webkit-animation: progress-animation2 2s linear infinite;
          animation: progress-animation2 2s linear infinite;
}

@-webkit-keyframes progress-animation2 {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0;
  }
}

@keyframes progress-animation2 {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0;
  }
}

body {
  background: linear-gradient(40deg, #33d18a34, #24a9a934 17%, #28b2a02c 34%, #33d18a28);
  background-size: 300% 100%;
  -webkit-animation: progress-animation 2s linear infinite;
          animation: progress-animation 2s linear infinite;
}

@-webkit-keyframes progress-animation {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0;
  }
}

@keyframes progress-animation {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0;
  }
}

.form_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: calc(100vh - 40px);
}

.form_container .logo_container {
  position: fixed;
  top: 1rem;
  left: 1rem;
  height: 70px;
  width: auto;
}

.form_container .form_inner_container {
  max-width: 550px;
  padding: 0rem 2rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.form_container .form_inner_container::-webkit-scrollbar {
  width: 7px;
}

.form_container .form_inner_container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.form_container .form_inner_container::-webkit-scrollbar-thumb {
  background: linear-gradient(125deg, #24a6a8 17%, #33d189 100%);
  border-radius: 10px;
}

.form_container .form_inner_container .stepper_header .progress {
  width: 100%;
  max-width: 100%;
  height: 8px;
  background: #e1e4e8;
  border-radius: 50px;
  overflow: hidden;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.form_container .form_inner_container .stepper_header .progress-bar {
  display: block;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(#ffd33d), color-stop(17%, #2dc095), color-stop(34%, #b34bff), color-stop(51%, #00c3cc), color-stop(68%, #ffd33d), color-stop(85%, #33d18a), to(#b34bff));
  background: linear-gradient(90deg, #ffd33d, #2dc095 17%, #b34bff 34%, #00c3cc 51%, #ffd33d 68%, #33d18a 85%, #b34bff);
  background-size: 300% 100%;
  -webkit-animation: progress-animation 2s linear infinite;
          animation: progress-animation 2s linear infinite;
}

@keyframes progress-animation {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0;
  }
}

.form_container .form_inner_container .stepper_header button {
  background: linear-gradient(125deg, #24a6a8 17%, #33d189 100%);
  color: #fff;
  border: 0;
  border-radius: 50px;
  height: 35px;
  -webkit-box-shadow: 1px 1px 4px #33d189;
          box-shadow: 1px 1px 4px #33d189;
}

.form_container .form_inner_container .stepper_header button:focus {
  outline: none;
}

.form_container .form_inner_container .form_body .step_one {
  padding: 2rem 0rem;
}

.form_container .form_inner_container .form_body .step_one .bottom_line {
  position: relative;
}

.form_container .form_inner_container .form_body .step_one .bottom_line::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0%;
  bottom: 12%;
  border-radius: 50px;
  background: -webkit-gradient(linear, left top, right top, from(#ffd33d), color-stop(17%, #2dc095), color-stop(34%, #b34bff), color-stop(51%, #00c3cc), color-stop(68%, #ffd33d), color-stop(85%, #33d18a), to(#b34bff));
  background: linear-gradient(90deg, #ffd33d, #2dc095 17%, #b34bff 34%, #00c3cc 51%, #ffd33d 68%, #33d18a 85%, #b34bff);
  background-size: 300% 100%;
  -webkit-animation: progress-animation 2s linear infinite;
          animation: progress-animation 2s linear infinite;
}

@keyframes progress-animation {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0;
  }
}

.form_container .form_inner_container .form_body .step_one .animation {
  margin: -11rem 0rem -6rem 0rem;
  position: relative;
}

.form_container .form_inner_container .form_body .step_one button {
  background: linear-gradient(125deg, #24a6a8 17%, #33d189 100%);
  color: #fff;
  border: 0;
  border-radius: 5px;
  height: 38px;
  -webkit-box-shadow: 1px 1px 4px #33d189;
          box-shadow: 1px 1px 4px #33d189;
  width: 100%;
  letter-spacing: 1px;
  font-weight: 500;
  cursor: pointer;
  position: absolute;
  bottom: 10%;
}

.form_container .radio_option {
  background: linear-gradient(125deg, #24a6a89a 17%, rgba(51, 209, 138, 0.603) 100%);
  margin: 0.6rem 0rem;
  padding: 0.3rem 0rem;
  color: #fff;
  border-radius: 6px;
}

.results_container {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.results_container .coach_card {
  min-height: 1px;
  height: 100%;
  -webkit-transition: 0.8s;
  transition: 0.8s;
}

.results_container .coach_card img {
  max-width: 200px;
  max-height: 200px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}

.results_container .coach_card:hover {
  -webkit-box-shadow: 1px 1px 5px #33d189;
          box-shadow: 1px 1px 5px #33d189;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.pop-up-container {
  position: fixed;
  left: 0%;
  right: 0%;
  bottom: 0%;
  top: 0%;
  width: 100%;
  height: 100vh;
  background: linear-gradient(125deg, #24a6a89a 17%, rgba(51, 209, 138, 0.603) 100%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 10;
  margin-top: 0px !important;
}

.pop-up-container .inner-pop-up {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  min-width: 900px;
  max-width: 500px;
  border-radius: 20px;
}

.pop-up-container .inner-pop-up::-webkit-scrollbar {
  width: 7px;
}

.pop-up-container .inner-pop-up::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.pop-up-container .inner-pop-up::-webkit-scrollbar-thumb {
  background: rgba(51, 209, 138, 0.603);
  border-radius: 10px;
}

.pop-up-container .inner-pop-up .cross {
  position: absolute;
  right: 0%;
  top: 0%;
}

.pop-up-container .inner-pop-up img {
  max-width: 150px;
  max-height: 150px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}

.payment_Container {
  margin: 3rem 0rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.payment_Container img {
  max-width: 150px;
  max-height: 150px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}

.payment_Container .btn_container {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.payment_Container .btn_container button {
  width: 100%;
}

.counter_container {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 3rem;
  -webkit-animation: blink-1 1s ease-in-out 0.2s infinite both;
  animation: blink-1 1s ease-in-out 0.2s infinite both;
}

.counter_container .countUp::after {
  content: "%";
}

@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

.pop-up-container.form-popup {
  position: fixed;
  left: 0%;
  right: 0%;
  bottom: 0%;
  top: 0%;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 10;
}

.pop-up-container.form-popup .inner-pop-up {
  background-color: #fff;
  min-width: 500px;
  max-width: 500px;
}

.pop-up-container.form-popup .pop-up-body {
  padding: 2rem;
}

.pop-up-container.form-popup .pop-up-body input {
  height: 44px;
  border-radius: 6px;
  padding: 0rem 1rem;
  border: 1px solid #000;
}

.pop-up-container.form-popup .pop-up-body input:focus {
  outline: none;
}

.pop-up-container.form-popup .pop-up-body button {
  background: linear-gradient(125deg, #24a6a89a 17%, rgba(51, 209, 138, 0.603) 100%);
  margin: 0.6rem 0rem;
  padding: 0.3rem 0rem;
  color: #fff;
  border-radius: 6px;
  border: none;
  height: 40px;
}

.pop-up-container.form-popup .error_field {
  border: 2px solid #dc3545 !important;
}

@media screen and (max-width: 1000px) {
  .pop-up-container .inner-pop-up {
    min-width: calc(100vw - 100px);
  }
}

@media screen and (max-height: 800px) {
  .pop-up-container .inner-pop-up {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }
}

@media screen and (max-width: 575px) {
  .pop-up-container {
    padding: 0rem 1rem;
  }
  .pop-up-container .inner-pop-up {
    max-height: 500px;
    overflow: auto;
  }
  .pop-up-container.form-popup .inner-pop-up {
    min-width: 1px;
  }
}

@media screen and (max-height: 700px) {
  .form_container .form_inner_container {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media screen and (max-height: 600px) {
  .form_container .form_inner_container {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
